import React from "react"
import { Button } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const scaigate = require("../images/scaigatelogo.png")
const lanlogo = require("../images/lanlogo.png")
const pcslogo = require("../images/pcslogo.png")

const OpenSource = () => (
  <Layout>
    <SEO
      title="Open Source Technologies - Flapmax"
      description=""
      lang="en"
      meta={[]}
    />

    <div className="hero-wrapper secondary open-source">
      <h1>Open Source </h1>

      <h3 className="text-center">
        Flapmax is a home for already fantastic open source technologies and
        many more to come.
      </h3>
    </div>

    {/* Open Source Section */}
    <section>
      <h2 className="text-center mb-5">Our Open Source Projects</h2>
      <div className="container">
        <div className="row d-flex justify-content-center">
          <a
            className="col-lg-4 open-source-item"
            href="https://langauge.org"
            target="_blank"
          >
            <img alt="SCAIGATE" src={lanlogo} width={161} height={100} />

            <h3>LanGauge</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, ex
              nihil rerum recusandae quidem tempore esse porro excepturi qui
              odit nulla accusamus voluptate obcaecati explicabo, eveniet
              debitis. Dolorem, labore dolor.
            </p>
          </a>
          <a
            className="col-lg-4 open-source-item"
            href="https://scaigate.org/pcs"
            target="_blank"
          >
            <img alt="PCS" src={pcslogo} height={100} />

            <h3>PCS</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, ex
              nihil rerum recusandae quidem tempore esse porro excepturi qui
              odit nulla accusamus voluptate obcaecati explicabo, eveniet
              debitis. Dolorem, labore dolor.
            </p>
          </a>
        </div>

        <div className="row d-flex justify-content-center mt-5">
          <a
            className="col-lg-4 open-source-item"
            href="https://scaigate.org"
            target="_blank"
          >
            <img alt="SCAIGATE" src={scaigate} width={235} height={100} />
            <h3>SCAIGATE</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, ex
              nihil rerum recusandae quidem tempore esse porro excepturi qui
              odit nulla accusamus voluptate obcaecati explicabo, eveniet
              debitis. Dolorem, labore dolor.
            </p>
          </a>
        </div>
      </div>
    </section>
  </Layout>
)

export default OpenSource
